.flex{
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom:6%;
}

.titleInfo{
  font-style: italic;
  font-weight: bold;
  font-size: 3em;
  padding-bottom: 0;
}

.infoSpec{
  font-size: 1.8em;
  margin-top:-15%;
  margin-bottom: -30%;
  text-decoration: underline;
}

.country{
  font-weight: bold;
  text-decoration: underline;
}

.city{
  margin-top:-9%;
}
