.footer{
  position: absolute;
  display:flex;
  justify-content:space-around;
  align-items:flex-end;
  bottom:0;
  left:0;
  width: 100%;
  flex-wrap: wrap;
}

.text-footer{
  color:white;
}

.background-terms{
  padding:60px;
  color:white;
  min-height: 100vh;
}

.made, a{
  color:white;
  text-decoration:none;
}

@media only screen and (max-width: 1200px) {
  .footer{
    position: relative;
  }
}