.admin{
  justify-content: space-around;
  flex-wrap:wrap;
  text-align: center;
  color: white;
  min-height:100vh
}

.buttonPosition{
  position: absolute;
  right:1%;
  top:1%;
}