.info{
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top:2vh;
  padding-bottom:4vh;
}

.text-info{
  word-wrap: break-word;
}

.title{
  color:white;
  font-size:80px;
  text-align: center;
}

.text-infos{
  color:white;
  font-size:24px; 
  text-align: center;
  position:relative;
}

.appstore{
    height:6vh;
    width:auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .store{
    display:flex;
    align-items:center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }


.mockup{
  height:94Vh;
  width:auto;
  &:hover{
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
  }
}



@media only screen and (max-width: 900px) {
  .mockup{
    width:80vw;
    height:auto
  }
}
