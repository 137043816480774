body{
  background:linear-gradient(135deg,rgb(16, 19, 26), rgb(51, 57, 78)); 
}

.App {
  height: 100%;
}

p a {
  color:white
}

